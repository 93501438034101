<template>
  <div class="o-staff">
    <aside class="name-en">
      {{ name.en }}
    </aside>
    <div class="info">
      <div class="thumb">
        <div class="inner">
          <div class="content">
            <img v-if="thumbUrl" :src="thumbUrl" :alt="name.jp">
            <a-blank-thumb v-else :label="name.en[0]" />
          </div>
        </div>
      </div>
      <div class="text">
        <h1 class="name-jp">{{ name.jp }}</h1>
        <div class="attr">
          <div class="role">{{ role }}</div>
          <div class="job">{{ job }}</div>
        </div>
        <div class="overview">{{ overview }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ABlankThumb from "../../Atoms/ABlankThumb/index.vue";

export default {
  name: "o-staff",
  components: {
    ABlankThumb
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    url: {
      type: String,
      default: "",
    },
    thumbUrl: {
      type: String,
      default: "",
    },
    name: {
      type: Object,
      default: {
        // en: string
        // jp: string
      },
    },
    job: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
    overview: {
      type: String,
    }
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>