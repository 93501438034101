<template>
  <div class="m-staff-list" :class="{ [size]: true }">
    <ul>
      <li v-for="(item, i) in items" :key="i">
        <a-staff v-bind="{ ...item, size }" />
      </li>
    </ul>
  </div>
</template>

<script>
import AStaff from "../../Atoms/AStaff/index.vue";

export default {
  name: "m-staff-list",
  components: {
    AStaff
  },
  props: {
    items: {
      type: Array,
      default: []
    },
    size: {
      type: String,
      default: 'normal',
      validator: function (value) {
        return ['normal', 'large'].indexOf(value) !== -1;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>