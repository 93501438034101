<template>
  <a class="a-staff" :href="url" :class="{ [size]: true }">
    <div class="image">
      <img v-if="thumbUrl" :src="thumbUrl" :alt="name">
      <a-blank-thumb v-else :label="name.en[0]" />
    </div>
    <div class="text">
      <p class="name" v-html="convertSpacesToBr(name.en)"></p>
      <p class="job">
        {{ job }}
      </p>
    </div>
  </a>
</template>

<script>
import ABlankThumb from "../ABlankThumb/index.vue";

export default {
  name: "a-staff",
  components: {
    ABlankThumb
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    url: {
      type: String,
      default: "",
    },
    thumbUrl: {
      type: [String, Boolean],
      default: "",
    },
    name: {
      type: Object,
      default: {
        // en: string
        // jp: string
      },
    },
    job: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: 'normal',
      validator: function (value) {
        return ['normal', 'large'].indexOf(value) !== -1;
      }
    },
  },
  methods: {
    convertSpacesToBr(text) {
      return text.replace(/ /g, '<span class="br"></span>');
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>