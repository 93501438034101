<template>
  <article class="o-edit-content">
    <div v-if="!mainContent" ref="content" :style="{ display: 'none' }">
      <slot />
    </div>

    <div v-html="leadContent" v-if="isShowToc"></div>
    <div class="toc" v-show="mainContent" v-if="isShowToc">
      <m-table-of-contents-list
        :items="heading"
      ></m-table-of-contents-list>
    </div>
    <div ref="main" v-html="mainContent"></div>
  </article>
</template>

<script>
import MTableOfContentsList from "../../Molecules/MTableOfContentsList/index.vue";

export default {
  name: "o-edit-content",
  data() {
    return {
      heading: [],
      leadContent: '',
      mainContent: '',
    }
  },
  components: {
    MTableOfContentsList
  },
  props: {
    isShowToc: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    mainContent() {
      this.$nextTick(() => {
        this.$refs.main.querySelectorAll('h2').forEach((v, i) => {
          v.id = `section-${i + 1}`;
        });

        this.heading = this.getHeading();
        this.$emit('mounted', this.heading);
      });
    }
  },
  mounted() {
    const contentText = this.$refs.content.innerHTML;
    const splitIndex = contentText.search(/<h2\b[^>]*>/);

    if (this.isShowToc) {
      this.leadContent = contentText.slice(0, splitIndex);
      this.mainContent = contentText.slice(splitIndex);
    } else {
      this.mainContent = contentText;
    }
  },
  methods: {
    getHeading() {
      const headings = this.$refs.main.querySelectorAll('h2');

      return Array.from(headings).map((heading) => {
        return { id: heading.id, text: heading.textContent };
      });
    }
  }
};
</script>

<style lang="scss">
@use "style";
</style>