<template>
  <p class="a-text" :class=" classes ">
    <slot />
  </p>
</template>

<script>
export default {
  name: "a-text",
  props: {
    size: {
      type: String,
      default: '1.6rem'
    },
    sizeMq: {
      type: Object,
      default: {
        // ssDown: String,
        // sgDown: String,
        // smDown: String,
        // mdDown: String,
        // lgDown: String,
        // xlDown: String,
        // xxlDown: String
      }
    },
    lineHeight: {
      type: String,
      default: '120%'
    },
    family: {
      type: String,
      default: 'jp'
    },
    weight: {
      type: String,
      default: 'regular'
    },
    color: {
      type: String,
      default: 'dark1'
    },
    align: {
      type: String,
      default: 'left',
      validator: function (value) {
        return ['left', 'center', 'right', 'justify'].indexOf(value) !== -1;
      }
    },
    ellipsis: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    return {
      weight: `var(--font-weight-${props.weight})`,
      family: `var(--font-family-${props.family})`,
      color: `var(--color-${props.color})`,
      classes: {
        ellipsis: props.ellipsis ? 'ellipsis' : '',
        ...props.sizeMq
      }
    }
  }
};

</script>

<style lang="scss" scoped>
@use "@global" as *;

.a-text {
  font-size: v-bind(size);
  line-height: v-bind(lineHeight);
  font-family: v-bind(family);
  font-weight: v-bind(weight);
  color: v-bind(color);
  text-align: v-bind(align);
}
.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: v-bind(ellipsis);
  line-clamp: v-bind(ellipsis);
  overflow: hidden;
  text-overflow: ellipsis;
}

.xxlDown {
  @include mq-down(xxl) {
    font-size: v-bind('sizeMq.xxlDown');
  }
}
.xlDown {
  @include mq-down(xl) {
    font-size: v-bind('sizeMq.xlDown');
  }
}
.lgDown {
  @include mq-down(lg) {
    font-size: v-bind('sizeMq.lgDown');
  }
}
.mdDown {
  @include mq-down(md) {
    font-size: v-bind('sizeMq.mdDown');
  }
}
.smDown {
  @include mq-down(sm) {
    font-size: v-bind('sizeMq.smDown');
  }
}
.sgDown {
  @include mq-down(sg) {
    font-size: v-bind('sizeMq.sgDown');
  }
}
.ssDown {
  @include mq-down(ss) {
    font-size: v-bind('sizeMq.ssDown');
  }
}
</style>