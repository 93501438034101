<template>
  <div class="m-tag-list">
    <ul>
      <li v-for="(item, i) in items" :key="i">
        <a-tag v-bind="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import ATag from "../../Atoms/ATag/index.vue";

export default {
  name: "m-tag-list",
  components: {
    ATag
  },
  props: {
    items: {
      type: Array,
      default: []
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>