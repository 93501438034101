<template>
  <div class="m-article-slider">
    <swiper
      :modules="modules"
      :breakpoints="{ 340: { slidesPerView: 1.5 }, 768: { slidesPerView: 2.5, } }"
      :space-between="20"
    >
      <swiper-slide v-for="(item, i) in items" :key="i">
        <a-article-small v-bind="item" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import AArticleSmall from "../../Atoms/AArticleSmall/index.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Mousewheel } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/mousewheel';

export default {
  name: "m-article-slider",
  components: {
    AArticleSmall,
    Swiper,
    SwiperSlide
  },
  props: {
    items: {
      type: Array,
      default: []
    }
  },
  setup() {
    return {
      modules: [Mousewheel]
    };
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>