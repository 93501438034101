<template>
  <a class="a-tag" :href="url">
    <p>{{ name }}</p>
  </a>
</template>

<script>
export default {
  name: "a-tag",
  props: {
    name: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>