<template>
  <a :href="url" class="a-company" target="_blank">
    <div class="logo">
      <img :src="thumbUrl" :alt="name" />
    </div>
    <div class="text">
      <div class="name">
        {{ name }}
      </div>
      <div class="overview">
        {{ overview }}
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "a-ranking",
  props: {
    name: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    overview: {
      type: String,
      default: "",
    },
    thumbUrl: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>