<template>
  <figure class="a-blank-thumb">
    <span class="label" :style="{ fontSize: `${fontSize}px` }">{{ label }}</span>
  </figure>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: "a-blank-thumb",
  data() {
    return {
      fontSize: 0
    }
  },
  mounted() {
    const fontSizeRatio = 4;


    nextTick(() => {
      this.fontSize = this.$el.offsetWidth / fontSizeRatio;
    });
  },
  props: {
    label: {
      type: String,
      default: ""
    }
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>