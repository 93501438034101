<template>
  <div class="a-navi">
    <div class="title">
      <div class="icon"></div>
      <slot name="title"></slot>
    </div>
    <div class="navi prev">
      <div class="icon arrow"></div>
      <slot name="prev"></slot>
    </div>
    <div class="navi next">
      <div class="icon arrow"></div>
      <slot name="next"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "a-navi",
};
</script>

<style lang="scss" scoped>
@use "style";
</style>