<template>
  <div class="m-article-list">
    <ul>
      <li v-for="(item, i) in items" :key="i">
        <a-article v-bind="item" />
      </li>
    </ul>
  </div>
</template>

<script>
import AArticle from "../../Atoms/AArticle/index.vue";

export default {
  name: "m-article-list",
  components: {
    AArticle
  },
  props: {
    items: {
      type: Array,
      default: []
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>