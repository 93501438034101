<template>
  <div class="m-table-of-contents-list">
    <p class="title">
      目次
    </p>
    <ul class="list">
      <li v-for="(item, i) in items" :key="i">
        <a :href="`#${item.id}`" v-smooth-scroll="{ offset: -80 }">{{ item.text }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "m-table-of-contents-list",
  props: {
    items: {
      type: Array,
      default: []
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>