<template>
  <div class="o-header">
    <div class="logo">
      <a :href="$root.siteUrl">
        <h1>UNTIC</h1>
        <p>Web担当者のための仕事メディア</p>
      </a>
    </div>
    <div class="menu">
      <ul>
        <li v-for="(link, i) in links" :key="i">
          <a :href="link.url" class="menu-item">
            <p>{{ link.label.jp }}</p>
            <aside>{{ link.label.en }}</aside>
          </a>
        </li>
      </ul>
      <div class="button">
        <a-button label="Contact" :href="$root.contactUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import AButton from "../../Atoms/AButton/index.vue";

export default {
  name: "o-header",
  components: { AButton },
  data() {
    return {
      links: [
        // {
        //   label: {
        //     jp: 'お知らせ',
        //     en: 'NEWS',
        //   },
        //   url: `${this.$root.siteUrl}/notices`
        // },
        {
          label: {
            jp: 'ブログ',
            en: 'BLOG',
          },
          url: `${this.$root.siteUrl}/blogs`
        },
        {
          label: {
            jp: '制作実績',
            en: 'WORK',
          },
          url: `${this.$root.siteUrl}/works`
        },
        {
          label: {
            jp: '運営チーム',
            en: 'TEAM',
          },
          url: `${this.$root.siteUrl}/staffs`
        },
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>