<template>
  <div class="a-page-title">
    <div class="top">
      <div class="character">
        <a-character />
      </div>
      <div class="title">
        <h2 v-html="title"></h2>
      </div>
    </div>
    <div class="bottom">
      <div class="body">
        <p v-html="body"></p>
      </div>
    </div>
  </div>
</template>

<script>
import ACharacter from "../ACharacter/index.vue";

export default {
  name: "a-page-title",
  components: { ACharacter },
  props: {
    title: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>