<template>
  <div class="a-button">
    <component :is="href ? 'a' : 'button'" :class="{ [color]: true }" :href="href" @click="onClick">
      {{ label }}
    </component>
  </div>
</template>

<script>
export default {
  name: "a-button",
  props: {
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: 'primary',
      validator: function (value) {
        return ['primary', 'secondary'].indexOf(value) !== -1;
      }
    },
    href: {
      type: String,
      default: null
    }
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click');
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>