const prefix = 'untic';

const setLocalStorage = (key, value) => {
  window.localStorage.setItem(`${prefix}-${key}`, JSON.stringify(value));
}

const getLocalStorage = (key, value) => {
  const item = window.localStorage.getItem(`${prefix}-${key}`);
  const data = item
    ? JSON.parse(item)
    : null;

  return data;
}

export {
  setLocalStorage,
  getLocalStorage
}