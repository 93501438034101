<template>
  <a class="a-article-small" :href="url">
    <div class="a-article-small__inner">
      <div class="wrapper">
        <div v-if="rank" class="rank">
          <div>
            <p>{{ rank }}</p>
          </div>
        </div>
        <div class="thumb">
          <template v-if="thumbUrl">
            <img :src="thumbUrl" :alt="title" loading="lazy" />
          </template>
          <template v-else>
            <figure :style="{ backgroundImage: `url(${ $root.getThemeUrl(images.bgBlank) })` }">
              <p>
                <span>{{ title }}</span>
              </p>
            </figure>
          </template>
        </div>
      </div>
      <div class="title" v-html="title">
      </div>
    </div>
  </a>
</template>

<script>
import ABlankThumb from "../ABlankThumb/index.vue";
import imageBgBlank from "./images/a-article-bg-blank.png";

export default {
  name: "a-article-ranking",
  data() {
    return {
      images: {
        bgBlank: imageBgBlank
      }
    }
  },
  components: {
    ABlankThumb
  },
  props: {
    url: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    thumbUrl: {
      type: String,
      default: "",
    },
    rank: {
      type: Number,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>