<template>
  <div class="o-footer">
    <div class="breadcrumb">
      <a-breadcrumb :pages="breadcrumbs" />
    </div>
    <div class="contact">
      <div class="inner">
        <div class="sec-logo">
          <div class="logo">
            <div class="text">
              <h3>UNTIC</h3>
              <p>WEB担当者のためのメディア</p>
            </div>
            <div class="character">
              <a-character />
            </div>
          </div>
        </div>
        <div class="sec-text">
          <h3 class="title">
            サービスに関してご不明な点がございましたら、お気軽にお問い合わせください。
          </h3>
          <ul>
            <li>サイト集客、SNS分析についてのお悩み</li>
            <li>ホームページ制作、LP制作についてのご相談</li>
          </ul>
          <p>など、お気軽にお問い合わせください。</p>
        </div>
        <div class="sec-button">
          <a-button label="Contact" :href="$root.contactUrl" />
        </div>
      </div>
    </div>
    <div class="footer-menu">
      <div class="menu">
      <!-- <div class="menu-item">
        <div class="menu-item__title">
          <p>サービス一覧</p>
          <h3>SERVICE</h3>
        </div>
        <div class="menu-item__submenu">
          <ul>
            <li>サービス名1</li>
            <li>サービス名2</li>
          </ul>
        </div>
      </div>
      <div class="menu-item">
        <div class="menu-item__title">
          <p>お役立資料</p>
          <h3>DOCUMENT</h3>
        </div>
        <div class="menu-item__submenu">
          <ul>
            <li>お役立ち資料</li>
          </ul>
        </div>
      </div>
      <div class="menu-item">
        <div class="menu-item__title">
          <p>特集記事</p>
          <h3>FEATURE</h3>
        </div>
        <div class="menu-item__submenu">
          <ul>
            <li>カテゴリ1</li>
            <li>カテゴリ2</li>
            <li>カテゴリ3</li>
            <li>カテゴリ4</li>
          </ul>
        </div>
      </div> -->
      <div class="menu-item">
        <!-- <div class="menu-item__title">
          <p>その他</p>
          <h3>OTHER</h3>
        </div> -->
        <div class="menu-item__submenu">
          <ul>
            <li v-for="(link, i) in links" :key="i">
              <a :href="link.url">
                {{ link.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright">
      <p>Copyright © UNTIC</p>
    </div>
    </div>
  </div>
</template>

<script>
import ABreadcrumb from "../../Atoms/ABreadcrumb/index.vue";
import AButton from "../../Atoms/AButton/index.vue";
import ACharacter from "../../Atoms/ACharacter/index.vue";

export default {
  name: "o-footer",
  components: { ABreadcrumb, AButton, ACharacter },
  data() {
    return {
      links: [
        {
          label: "運営チーム",
          url: `${this.$root.siteUrl}/staffs`
        },
        {
          label: "サイトポリシー",
          url: `${this.$root.siteUrl}/guide`
        },
        {
          label: "お問い合わせ",
          url: this.$root.contactUrl
        },
      ]
    }
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: [
        // {
        //   name: String,
        //   url: String,
        // }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>