<template>
  <div class="a-character">
    <img :src="$root.getThemeUrl(images.character)" alt="UNTIC">
  </div>
</template>

<script>
import imageCharacter from "./images/a-character.svg";

export default {
  name: "a-character",
  data() {
    return {
      images: {
        character: imageCharacter
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>