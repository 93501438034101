<template>
  <div class="a-pagination">
    <button class="arrow prev" v-show="currentPage !== 1" @click="onClick(currentPage - 1)"></button>
    <ul>
      <li class="pagination" v-if="!isFirstRange">
        <p class="page ellipsis">⋯</p>
      </li>
      <li class="pagination" v-for="n in displayPage" :key="n">
        <button class="page" :class="{ active: currentPage === n }" @click="onClick(n)">{{ n }}</button>
      </li>
      <li class="pagination" v-if="!isLastRange">
        <p class="page ellipsis">⋯</p>
      </li>
    </ul>
    <button class="arrow next" v-show="currentPage !== totalPage" @click="onClick(currentPage + 1)"></button>
    <slot name="page" :n="selectedPage"></slot>
  </div>
</template>

<script>
export default {
  name: "a-pagination",
  data() {
    return {
      selectedPage: null,
    }
  },
  emits: ['click'],
  methods: {
    onClick(n) {
      this.$emit('click', n);
      this.selectedPage = n;
    },
  },
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 0,
    },
    buttonRange: {
      type: Number,
      default: 2,
    }
  },
  computed: {
    displayPage() {
      const totalPage = this.totalPage;
      const currentPage = this.currentPage;
      const buttonRange = this.buttonRange;
      const buttonRangeFull = buttonRange * 2;

      const pageRange = [...Array(totalPage)].map((_, i) => i + 1);

      let currentRange = pageRange.filter(item => {
        let from = currentPage - buttonRange;
        let to = currentPage + buttonRange;

        if (from <= 0) {
          from = 1;
          to = buttonRangeFull + 1;
        } else if (to > totalPage) {
          from = totalPage - buttonRangeFull;
          to = totalPage;
        };

        return item >= from && item <= to;
      });

      return currentRange;
    },
    isFirstRange() {
      return this.displayPage.includes(1);
    },
    isLastRange() {
      return this.displayPage.includes(this.totalPage);
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>