<template>
  <div class="vc-button">
    <button>
      {{ label }}
    </button>
  </div>
</template>

<script>
export default {
  name: "vc-button",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>
