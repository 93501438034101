<template>
  <div class="m-navi-adjacent-post">
    <div class="category">
      <a :href="list.url"><p>{{ list.title }}</p></a>
    </div>
    <div class="item prev" :class="{ 'empty': !$slots.prev }">
      <template v-if="$slots.prev">
        <slot name="prev" />
      </template>
      <template v-else>
        <p>-</p>
      </template>
    </div>
    <div class="item next" :class="{ 'empty': !$slots.next }">
      <template v-if="$slots.next">
        <slot name="next" />
      </template>
      <template v-else>
        <p>-</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "m-navi-adjacent-post",
  props: {
    list: {
      type: Object,
      default: {
        title: 'List',
        url: null
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>