<template>
  <nav class="a-breadcrumb">
    <ul>
      <li v-for="(page, i) in pages" :key="i">
        <a :href="page.url" class="link" :class="{current: i === (pages.length-1)}" v-html="page.name">
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "a-breadcrumb",
  props: {
    pages: {
      type: Array,
      default: [
        // {
        //   name: String,
        //   url: String,
        // }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>