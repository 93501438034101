<template>
  <div class="a-title">
    <h2>{{ jp }}</h2>
    <aside>{{ en }}</aside>
  </div>
</template>

<script>
export default {
  name: "a-title",
  props: {
    jp: {
      type: String,
      default: "",
    },
    en: {
      type: String,
      default: "",
    },
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>