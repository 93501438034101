<template>
  <div class="o-article">
    <h2 class="title" v-text="title"></h2>
    <div class="info">
      <p class="date">{{ $root.formatDate(date) }}</p>
      <div class="category">
        <ul>
          <li v-for="(category, i) in categories" :key="i">
            <a class="category-item" :href="category.url">
              {{ category.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="staff">
      <a-staff v-bind="author" />
    </div>
    <div class="image">
      <template v-if="thumbUrl">
        <img :src="thumbUrl" :alt="title" width="100%" height="auto">
      </template>
      <template v-else>
        <figure :style="{ backgroundImage: `url(${ $root.getThemeUrl(images.bgBlank) })` }">
          <p>
            <span>{{ title }}</span>
          </p>
        </figure>
      </template>
    </div>
  </div>
</template>

<script>
import AStaff from "../../Atoms/AStaff/index.vue";
import imageBgBlank from "./images/o-article-bg-blank.png";

export default {
  name: "o-staff",
  data() {
    return {
      images: {
        bgBlank: imageBgBlank
      }
    }
  },
  components: {
    AStaff
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    categories: {
      type: Array,
      default: [
        // number
      ],
    },
    thumbUrl: {
      type: String,
      default: "",
    },
    author: {
      type: Object,
      default: {
        // id: Number,
        // url: String,
        // thumbUrl: String,
        // name: {
        //   jp: String,
        //   en: String,
        // },
        // job: String
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>