<template>
  <a class="a-banner" :href="url" :target="isBlank ? '_blank' : '_self'">
    <img :src="thumbUrl" :alt="name" width="100%" height="auto">
  </a>
</template>

<script>
export default {
  name: "a-banner",
  props: {
    name: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    isBlank: {
      type: Boolean,
      default: false
    },
    thumbUrl: {
      type: String,
      default: false
    },
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>