<template>
  <div class="l-main">
    <div class="header">
      <o-header />
    </div>
    <div class="content">
      <main class="main">
        <slot name="main" />
      </main>
      <aside class="sub">
        <slot name="sub" />
      </aside>
    </div>
    <div class="footer">
      <o-footer :breadcrumbs="breadcrumbs" />
    </div>
  </div>
</template>

<script>
import OHeader from "../../Organisms/OHeader/index.vue"
import OFooter from "../../Organisms/OFooter/index.vue"

export default {
  name: "l-main",
  components: {
    OHeader,
    OFooter
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: [
        // {
        //   name: String,
        //   url: String,
        // }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
@use "style";
</style>