<template>
  <a class="a-article" :href="url">
    <div class="thumb">
      <template v-if="thumbUrl">
        <img :src="thumbUrl" :alt="title" loading="lazy" />
      </template>
      <template v-else>
        <figure :style="{ backgroundImage: `url(${ $root.getThemeUrl(images.bgBlank) })` }">
          <p>
            <span v-html="title"></span>
          </p>
        </figure>
      </template>
    </div>
    <div class="text">
      <div class="detail">
        <div class="date">
          {{ $root.formatDate(date) }}
        </div>
        <div class="category">
          <ul>
            <li v-for="(category, i) in categories" :key="i">
              <span class="category-item" :href="category.link">
                {{ category.name }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="title" v-html="title">
      </div>
      <div class="author">
        <div class="image">
          <img v-if="author.thumbUrl" :src="author.thumbUrl" :alt="author.name.jp">
          <a-blank-thumb v-else :label="author.name.en[0]" />
        </div>
        <div class="name">
          {{ author.name.en }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import ABlankThumb from "../ABlankThumb/index.vue";
import imageBgBlank from "./images/a-article-bg-blank.png";

export default {
  name: "a-article",
  data() {
    return {
      images: {
        bgBlank: imageBgBlank
      }
    }
  },
  components: {
    ABlankThumb
  },
  props: {
    url: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    thumbUrl: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    categories: {
      type: Array,
      default: [
        // {
        //   id: Number,
        //   name: String,
        //   url: String,
        // }
      ],
    },
    author: {
      type: Object,
      default: {
        // id: Number,
        // url: String,
        // thumbUrl: String,
        // name: {
        //   jp: String,
        //   en: String,
        // },
        // job: String
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "style";
</style>